@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

.biDashboard .MuiCardHeader-title {
    font-family: 'Inter';          
    padding-left: 10px;   
}

.biDashboard .custom-paper {
  border-radius: 15px;
  box-shadow: 4px 4px 4px rgba(109, 106, 106, 0.6);
  overflow: hidden;
}
  
.biDashboard .MuiCard-root {
  border: 2px solid #6fa4bf; 
  border-radius: 8px;
  overflow: hidden;
}

.biDashboard .MuiCardContent-root {
  padding-top: 20px;
}

.biDashboard .modebar {
  position: absolute;
  top: 50px !important;
  right: 10px !important;
}
  