/* Common button styles */
.button {
  min-width: 100px;
  text-transform: capitalize !important;
  border-radius: 12px !important;
  box-shadow: 4px;
  /* background: linear-gradient(45deg, #ffee93 30%, #ffee93 90%); */
  color: black;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.button:hover {
    transform: scale(1.05);
  background: linear-gradient(45deg, #9C1A35 30%, #9C1A35 90%);
  color: white;
}

/* Header section styling */
.header {
  padding: 1rem;
}

.header-button-container {
  display: flex;
  justify-content: flex-start;
}

/* Table container */
.table-container {
  border-radius: 14px !important;
  border: 1px solid;
  box-shadow: 30px;
  max-height: 505px;
}

/* .table-container::-webkit-scrollbar {
  width: 4px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 8px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
} */


/* Table headers */
.table-header-cell {
  /* color: rgb(245, 231, 231) !important;  */
  font-weight: 500;
  text-align: center;
  padding: 0.3rem 0.6rem 0.3rem 0.8rem;
  border-bottom: 1px solid #0e0a0a !important;
  background: linear-gradient(45deg, #cfcfcf 30%, #cfcfcf 90%);
}

/* Table rows */
.table-row {
  background: white;
  cursor: pointer;
  transition: background 0.3s ease;
  vertical-align: middle;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.table-row:not(.table-row-selected):hover {
  background: linear-gradient(45deg, #e9e9e8 30%, #e9e9e8 90%);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transform: scale(0.98);
}

.table-row-selected {
  background: linear-gradient(45deg, #be344f 30%, #be344f 90%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Table cell styles */
.table-cell {
  
  text-align: left !important;
  vertical-align: middle;
}

/* rx */

.rx-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px solid #cacaca;
  border-radius: 6px;
  background-color: #eeeeec;
  width: 300px;
}

.rx-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #cacaca;
}

.therapy-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 8px;
}

.therapy-tag {
  display: inline-block;
  background: linear-gradient(45deg, #655ebb 30%, #788ce6 90%);
  color: white;
  padding: 0.2em 0.5em;
  border-radius: 0.3rem;
  margin: 0.2rem;
  font-size: 0.75rem;
}


/* Bill card container */
.bill-card {
  flex-grow: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: 4px;
  border-radius: 12px;
}

.bill-card-header {
  background: linear-gradient(45deg, #d9dfe2 30%, #d9dfe2 90%);
  color: rgb(14, 13, 13);
  border: 1px solid black;
  border-radius: 12px;
  box-shadow: 40px;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.bill-card-body {
  margin: 0.5rem;
  /* background: linear-gradient(45deg, #ffffff 30%, #ffffff 90%); */
  border-radius: 10px;
}

.notes-section {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 0.5rem;
  background: white;
  border-radius: 12px;
  border: 1px solid;
  box-shadow: 4px;
  width: 100%;
  color: #333;
}

.notes-textfield {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
  background: #f9f9f9;
  font-size: 1rem;
  color: #333;
  transition: box-shadow 0.3s ease;
}

.notes-textfield:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(156, 26, 53, 0.8);
  border-color: #9C1A35;
}

/* Dialog styles */
.dialog {
  padding: 1rem;
  background: linear-gradient(135deg, #fff7cc 30%, #ffe680 90%);
  border-radius: 12px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.dialog-title {
  font-weight: bold;
  font-size: 1.2rem;
  color: #9C1A35;
  text-align: center;
  margin-bottom: 0.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.dialog-content {
  padding: 0.5rem;
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
}

.dialog-actions {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.dialog-button {
  padding: 0.2rem 0.7rem;
  font-size: 0.8rem;
  border-radius: 12px;
  background: linear-gradient(45deg, #ffee93 30%, #ffee93 90%);
  color: black;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dialog-button:hover {
  transform: scale(1.05);
  background: linear-gradient(45deg, #9C1A35, #003059);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Loading section */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading-chip {
  margin-top: 1rem;
  background-color: #f0f0f0;
  color: #333;
  font-weight: bold;
  font-size: 1rem;
  padding: 10px;
}

/* Box section */
.box-container {
  margin: 1rem;
}

.box {
  background: white;
  color: black;
  border: 1px solid;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 14px;
  padding: 0.5rem;
}