.drag-and-drop-container {
  display: flex !important;
  flex-direction: column !important;
}

.drop-target {
  background-color: rgb(184, 187, 184)!important;
  border: 2px dashed grey !important;
  text-align: center !important;
  animation: moving-dash 3s linear infinite !important;
}

/* Keyframe animation for the moving dashed border effect */
@keyframes moving-dash {
  0% {
    border-image-source: linear-gradient(90deg, grey 25%, transparent 25%);
    border-image-slice: 1;
    border-image-width: 2px;
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}


.dragging-source {
  opacity: 0.5 !important;
}

.dropped-target {
  background-color: lightyellow !important;
}
